.productsHeading {
  margin: 2vmax auto;
  /* width: 15vw; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.171);
  padding: 2vmax;
  color: rgba(0, 0, 0, 0.678);
  font: 500 1.5vmax ;
  text-align: center;
}

.productsHeading img{
  height: 150px;
}

.modalHeading{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eee;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
   
}

.products {
  flex:1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5vmax;
  justify-content: center;
  background-color: white;
  
 
}

.paginationBox {
  display: flex;
  justify-content: center;
  margin: 2vmax;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  transition: all 0.3s;
  cursor: pointer;
}
.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}
.page-link {
  text-decoration: none;
  font: 300 0.7vmax ;
  color: rgb(80, 80, 80);
  transition: all 0.3s;
}

.page-item:hover {
  background-color: rgb(230, 230, 230);
}

.page-item:hover .page-link {
  color: rgb(0, 0, 0);
}

.pageItemActive {
  background-color: tomato;
}

.pageLinkActive {
  color: white;
}

.container{
  display: flex;
  max-width: 1350;

}

.filterBox::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.filterBox {
  width: 8.5vmax;
  height: 1200px;
  overflow-y: auto;
  padding: 5px 10px;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.categoryBox {
  padding: 0%;
}

.category-link {
  list-style: none;
  color: rgba(0, 0, 0, 0.61);
  transition: all 0.5s;
  font-size: 13.5px;
  margin: 0.4vmax;
  cursor: pointer;
  transition: all 0.5s;
}

.category-link-high {
  list-style: none;
  color: tomato;
  margin: 0.4vmax;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 13.5px;  
  font-weight: 700;
}
.category-link:hover {
  color: tomato;
}

.filterBox > fieldset {
  border: 1px solid rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 600px) {
  .filterBox {
    width: 20vmax;
    position: static;
    margin: 0 auto;
  }

  .page-link {
    font: 300 1.7vmax ;
  }
  .category-link {
    font: 400 1.8vmax ;
  }
}